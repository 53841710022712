import styles from './Form.module.css';
import {Zoom} from "react-awesome-reveal";
import React from "react";
function Form(props) {
  return (
      <div className="wrapper" style={{paddingTop: 100, paddingBottom: 100}}>
          <h1>Impressum</h1>

          <div>
              <p>Angaben gemäß § 5 TMG:</p>
              <p><strong>Clean Port Gebäudemanagement GmbH</strong>
                  <br/><br/>Gutenbergring 67B
                  <br/>22848 Norderstedt
              </p>
              <p><strong>Kontakt</strong></p>
              <p>Telefon: 040 21 97 29 91
                  <br/>E-Mail:&nbsp;&nbsp; &nbsp;<a href="mailto:info@cp-gm.de">info@cp-gm.de</a>
              </p><p>
              <strong>Geschäftsführer</strong>: Muhammed Kement</p><p>Aufsichtsbehörde: Amtsgericht Hamburg HRB
              164370</p><p>Quellenangaben für die verwendeten Bilder und Grafiken: Unsplash, Pexels, Pixabay, iStock,
              freepik</p>
              <p><strong>Haftungsausschluss (Disclaimer)</strong></p><p>Haftung für Inhalte</p><p>Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
              oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
              rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
              jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
              entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p><p>Haftung für Links</p>
              <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                  Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                  verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                  verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                  Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                  Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                  zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
              <p>Urheberrecht</p><p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
              unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
              Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
              Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
              trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
              Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
              <p>Quelle:&nbsp;<a href="http://www.e-recht24.de/">http://www.e-recht24.de</a></p></div>
      </div>
  );
}

export default Form;
