import styles from './Form.module.css';
import imgStern from './imgStern.png'
import imgBackground from "./imgBackground.png";
import img21 from "../area02/ref21.png";
import img2 from "../area02/ref2.png";
import img3 from "../area02/ref20.jpg";
import img4 from "../area02/ref17.png";
import img5 from "../area02/ref16.png";
import img6 from "../area02/ref6.jpg";
import img7 from "../area02/ref5.jpg";
function Form(props) {

    const FOOTER = (props) => (

        <div className={styles.item}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <img src={props.img} style={{maxHeight: '40px', maxWidth: '100px', marginBottom: '-7px'}}/>
                    </td>
                    <td style={{width: '100%'}}>
                        <div className={styles.refCommentName}>
                            {props.name}<br/>
                            <b>{props.company}</b>
                        </div>
                    </td>
                    <td>
                        <img src={imgStern} style={{width: 100}}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
  return (
      <div style={{backgroundImage: 'url("' + imgBackground + '")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
          <div className="wrapperSlim">
              <center>
                  <div className={styles.header}>Zufriedene Kundenstimmen</div>
                  <div className={styles.text}>Von sauberer Arbeit erzählt man gerne …</div>

              </center>
              <br/>
              <div className="grid">
                  <div className={"lg:col-6 col-12"} >
                      <FOOTER
                          img={img21}
                          name="S.B."
                          company="PRAXISKLINIK WINTERHUDE"/>
                      <div className={styles.refText}>"Als ambulante Praxis werden Hygiene- und Reinigungsabläufe bei uns großgeschrieben. Clean Port ist hierbei seit mehreren Jahren unser zuverlässiger Partner"</div>
                  </div>
                  <div className={"lg:col-6 col-12"}>
                      <FOOTER
                          img={img2}
                          name="M.V."
                          company="MIM"/>
                      <div className={styles.refText}>"Unser Großraumbüro, welches auch eine Produktionsstätte für medizinische Instrumente ist, wird seit mehr als fünf Jahren seitens Clean Port gereinigt. Anfragen für Sonderreinigungen werden immer kurzfristig bearbeitet. Wir sind sehr zufrieden"</div>
                  </div>
                  <div className="lg:col-6 col-12">
                      <FOOTER
                          img={img3}
                          name="F.H."
                          company="MISSION BILDUNGSCHANCE"/>
                      <div className={styles.refText}>"Die Zusammenarbeit funktioniert reibungslos, genau das was wir von einem Reinigungsdienstleister erwarten"</div>
                  </div>
                  <div className="lg:col-6 col-12">
                      <FOOTER
                          img={img4}
                          name="V.T."
                          company="CON. ME"/>
                      <div className={styles.refText}>"Wegen Unzufriedenheit mit dem vorherigen Dienstleister hatten wir im Sommer 2019 zu Clean Port gewechselt. Seitdem alles tip top"</div>
                  </div>
                  <div className="lg:col-6 col-12">
                      <FOOTER
                          img={img5}
                          name="Y.I."
                          company="KKH"/>
                      <div className={styles.refText}>"Nach einer kurzen Eingewöhnungsphase, indem Prozesse besprochen und Abläufe geregelt wurden, haben wir so gut wie nichts mehr von Clean Port mitbekommen. Wir finden unsere Räume immer sauber vor."</div>
                  </div>
                  <div className="lg:col-6 col-12">
                      <FOOTER
                          img={img6}
                          name="I.S."
                          company="DULFS BURGER"/>
                      <div className={styles.refText}>"Mit besonderen Pflegeanforderungen von gewissen Oberflächen waren die Mitarbeiten von Clean Port schon im Vorfeld bewandert, gerne weiter"</div>
                  </div>
                  <div className="lg:col-6 col-12">
                      <FOOTER
                          img={img7}
                          name="M.M."
                          company="H&S SOLUTIONS"/>
                      <div className={styles.refText}>"Direkte Abhilfe bei Anliegen und eine kurzfristige Ausführung"</div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Form;
