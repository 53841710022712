import styles from './Form.module.css';
import {Zoom} from "react-awesome-reveal";
function Form(props) {
  return (
      <div className="wrapper" style={{paddingTop: 100, paddingBottom: 100}}>
          <h1>Datenschutz</h1>
          <div >
              <p><strong>1. Datenschutz auf einen Blick</strong></p><p>Allgemeine Hinweise</p><p>Die folgenden Hinweise
              geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere
              Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden
              können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
              aufgeführten Datenschutzerklärung.</p><p>Datenerfassung auf unserer Website</p><p><strong>Wer ist
              verantwortlich für die Datenerfassung auf dieser Website?</strong></p><p>Die Datenverarbeitung auf dieser
              Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website
              entnehmen.</p><p><strong>Wie erfassen wir Ihre Daten?</strong></p><p>Ihre Daten werden zum einen dadurch
              erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein
              Kontaktformular eingeben.</p><p>Andere Daten werden automatisch beim Besuch der Website durch unsere
              IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
              betreten.</p><p><strong>Wofür nutzen wir Ihre Daten?</strong></p><p>Ein Teil der Daten wird erhoben, um
              eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
              Nutzerverhaltens verwendet werden.</p><p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
          </p><p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
              gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
              Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
              können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
              Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p><p>Außerdem haben Sie das Recht,
              unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              Details hierzu entnehmen Sie der Datenschutzerklärung unter „Recht auf Einschränkung der
              Verarbeitung“.</p><p>Analyse-Tools und Tools von Drittanbietern</p><p>Beim Besuch unserer Website kann Ihr
              Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten
              Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann
              nicht zu Ihnen zurückverfolgt werden.</p><p>Sie können dieser Analyse widersprechen oder sie durch die
              Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen zu diesen Tools und über Ihre
              Widerspruchsmöglichkeiten finden Sie in der folgenden Datenschutzerklärung.</p><p><b>2. Allgemeine
              Hinweise und Pflichtinformationen</b></p><p><b>Datenschutz</b></p><p>Die Betreiber dieser Seiten nehmen
              den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
              entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p><p>Wenn Sie
              diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
              Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
              erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
              das geschieht.</p><p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei der
              Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
              Zugriff durch Dritte ist nicht möglich.</p><p><b>Hinweis zur verantwortlichen Stelle</b></p><p>Die
              verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p><p>Clean Port
              Gebäudemanagement GmbH<br/>Hermannstal 38d<br/>22119 Hamburg</p><p>Telefon: 040 46 98 96 92<br/>E-Mail:
              info@cp-gm.de</p><p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B.
              Namen, E-Mail-Adressen o. Ä.) entscheidet.</p><p><b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
          </p><p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können
              eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
              an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.</p><p><b>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
              Direktwerbung (Art. 21 DSGVO)</b></p><p>Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
              oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
              ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für
              ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
              Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
              Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
              schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
              überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              (Widerspruch nach Art. 21 Abs. 1 DSGVO).</p><p>Werden Ihre personenbezogenen Daten verarbeitet, um
              Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie
              betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre
              personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach
              Art. 21 Abs. 2 DSGVO).</p><p><b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b></p><p>Im Falle
              von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
              insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
              mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
              oder gerichtlicher Rechtsbehelfe.</p><p><b>Recht auf Datenübertragbarkeit</b></p><p>Sie haben das Recht,
              Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu
              lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt
              dies nur, soweit es technisch machbar ist.</p><p><b>SSL- bzw. TLS-Verschlüsselung</b></p><p>Diese Seite
              nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
              Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung.
              Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf
              „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p><p>Wenn die SSL- bzw.
              TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten
              mitgelesen werden.</p><p><b>Auskunft, Sperrung, Löschung und Berichtigung</b></p><p>Sie haben im Rahmen
              der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung
              und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
              zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
              uns wenden.</p><p><b>Recht auf Einschränkung der Verarbeitung</b></p><p>Sie haben das Recht, die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der
              Verarbeitung besteht in folgenden Fällen:</p>
              <ul>
                  <li><p>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
                      benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
                      Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p></li>
                  <li><p>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                      statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</p></li>
                  <li><p>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                      Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                      Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p></li>
                  <li><p>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
                      zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
                      Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                      personenbezogenen Daten zu verlangen.</p></li>
              </ul>
              <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                  ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                  juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
                  oder eines Mitgliedstaats verarbeitet werden.</p><p><b>3. Datenerfassung auf unserer Website</b></p>
              <p><b>Cookies</b></p><p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
              Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
              nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
              Rechner abgelegt werden und die Ihr Browser speichert.</p><p>Die meisten der von uns verwendeten Cookies
              sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere
              Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns,
              Ihren Browser beim nächsten Besuch wiederzuerkennen.</p><p>Sie können Ihren Browser so einstellen, dass
              Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
              Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim
              Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser
              Website eingeschränkt sein.</p><p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
              oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.&nbsp;B. Warenkorbfunktion)
              erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber
              hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten
              Bereitstellung seiner Dienste. Soweit andere Cookies (z.&nbsp;B. Cookies zur Analyse Ihres Surfverhaltens)
              gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.</p><p>
              <b>Kontaktformular</b></p><p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
              Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
              der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
              Ihre Einwilligung weiter.</p><p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
              somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese
              Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
              Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.</p><p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
              Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
              insbesondere Aufbewahrungsfristen – bleiben unberührt.</p><p><b>Anfrage per E-Mail, Telefon oder
              Telefax</b></p><p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive
              aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres
              Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung
              weiter.</p><p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern
              Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6
              Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
              berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.</p><p>Die von
              Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
              auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
              (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p><p><b>4. Plugins und Tools</b></p>
              <p>Google Maps</p><p>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google
              Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p><p>Zur Nutzung der
              Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in
              der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite
              hat keinen Einfluss auf diese Datenübertragung.</p><p>Die Nutzung von Google Maps erfolgt im Interesse
              einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns
              auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
              DSGVO dar.</p><p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von
              Google:&nbsp;<a href="https://policies.google.com/privacy?hl=de" target="_blank"
                              rel="noreferrer noopener">https://policies.google.com/privacy?hl=de</a>.</p><p>Google
              reCAPTCHA</p><p>Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf unseren Websites. Anbieter
              ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p><p>Mit
              reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.&nbsp;B. in einem
              Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert
              reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt
              automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene
              Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom
              Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.</p>
              <p>Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf
                  hingewiesen, dass eine Analyse stattfindet.</p><p>Die Datenverarbeitung erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor
              missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen.</p><p>Weitere Informationen zu
              Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie folgenden Links:&nbsp;<a
                  href="https://policies.google.com/privacy?hl=de" target="_blank"
                  rel="noreferrer noopener">https://policies.google.com/privacy?hl=de</a>&nbsp;und&nbsp;<a
                  href="https://www.google.com/recaptcha/intro/android.html" target="_blank"
                  rel="noreferrer noopener">https://www.google.com/recaptcha/intro/android.html</a>.</p><p>Quelle:&nbsp;
              <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">https://www.e-recht24.de/muster-datenschutzerklaerung.html</a>
          </p></div>
      </div>
  );
}

export default Form;
