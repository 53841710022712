import styles from './Form.module.css';
import imgUberUns1 from './imgUberUns1.jpg'
import imgUberUns2 from './imgUberUns2.jpg'
import imgUberUns3 from './imgUberUns3.jpg'
import imgUberUns4 from './imgUberUns4.jpg'
import imgUberUns5 from './imgUberUns5.jpg'
import {Zoom} from "react-awesome-reveal";
import React from "react";
function Form(props) {
  return (
      <div>

          <div style={{background: '#276A9F'}}>
              <center>
                  <div className={styles.header}>Zertifizierte Büroreinigung</div>
              </center>
          </div>

          <div className="wrapper">

              <div className="grid">
                  <div className="displayLarge lg:col-6 col-12">
                      <div style={{paddingTop: '20px'}}>
                          <div style={{width: '50%', float: "left"}}>
                              <Zoom direction="left" triggerOnce>
                                  <img className={styles.img} src={imgUberUns1}/>
                              </Zoom>
                          </div>
                          <div style={{width: '50%', float: "left", marginLeft: '-10%', marginTop: '10%'}}>
                              <Zoom direction="right" triggerOnce>
                                  <img className={styles.img} src={imgUberUns2}/>
                              </Zoom>
                          </div>
                      </div>
                  </div>
                  <div className={" lg:col-6 col-12"}>
                      <Zoom triggerOnce direction="right">
                          <div style={{
                              borderRadius: 15
                              , background: 'rgba(255,255,255,0.4)'
                              , padding: 25
                          }}>

                              <div>
                                  <br/>
                                  Für Sie bringen wir Effizienz und Verlässlichkeit auf einen Nenner. Clean Port Gebäudemanagement GmbH ist nach ISO 9001:2015 zertifiziert. Die Norm unterstützt uns dabei, die Kundenzufriedenheit zu erhöhen und unsere Effizienz zu steigern. Daher dürfen Sie sicher sein, dass wir bis ins kleinste Detail saubere Arbeit leisten.
                                  <br/>
                                  <br/>
                              </div>
                          </div>

                      </Zoom>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Form;
