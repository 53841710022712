import styles from './Form.module.css';
import imgBackground from "./imgBackground.png";
import imgHand from "./imgHand.png";
import imgHandMobile from "./imgHandMobile.png";
import {Button} from "primereact/button";
import React, {useEffect} from "react";
import {Bounce, Zoom, Fade, JackInTheBox} from "react-awesome-reveal";
function Form(props) {

  return (
      <div ref={props.childRef} style={{backgroundImage: 'url("' + imgBackground + '")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>

          <div style={{position: 'fixed', width: '100%', zIndex: 999999999}}>
              <a href="tel://04021972991">
                  <div className={styles.phone}>040 21 97 29 91</div>
              </a>


          </div>


          <center>
              <Fade triggerOnce delay="80">
                  <div className={styles.header}>
                      Die lösungsorientierte Büroreinigung
                  </div>
              </Fade>
          </center>
          <div>
              <div style={ window.innerWidth >= 992 ? {position: 'absolute', marginLeft: '26%', marginRight: '26%', marginTop: '2.5%', zIndex: 99} : {zIndex: 99}}>
                  <video
                      controls
                      preload="auto"
                      loop
                      width="100%"
                      id="video"
                      src="/video.mp4" type="video/mp4"

                      ref={props.videoRef}>
                  </video>
              </div>
              {window.innerWidth >= 992 && (
                  <JackInTheBox triggerOnce>
                      <img src={window.innerWidth >= 992 ? imgHand : imgHandMobile} style={{width: '100%'}} />
                  </JackInTheBox>
              )}
          </div>


          <div className="wrapperSlim">
              <div className="grid">
                  <div className="col-4">
                      <Zoom triggerOnce direction="left">
                          <div className={styles.button}>
                              <div style={{fontWeight: 'bold'}}>Kostenlose</div>
                              Nachbesserung
                          </div>
                      </Zoom>
                  </div>
                  <div className="col-4">

                      <Zoom triggerOnce delay="20">
                          <div className={styles.button}>
                              <div style={{fontWeight: 'bold'}}>24 Stunden</div>
                              persönlicher Kontakt
                          </div>
                      </Zoom>
                  </div>
                  <div className="col-4">
                      <Zoom triggerOnce direction="right">
                          <div className={styles.button}>
                              <div style={{fontWeight: 'bold'}}>Monatlich</div>
                              kündbar
                          </div>
                      </Zoom>
                  </div>
              </div>
              <center>
                  <Bounce triggerOnce delay="60">
                      <div className="terminButton" onClick={() => props.onTerminVereinbarenClick && props.onTerminVereinbarenClick()}>
                          <div style={{marginBottom: -5, fontSize: window.innerWidth >= 992 ? 22 : 16}}>Termin vereinbaren</div>
                          <div style={{fontSize: window.innerWidth >= 992 ? 16 : 10}}>kostenloses Angebot</div>
                      </div>
                  </Bounce>
              </center>
          </div>
      </div>
  );
}

export default Form;
