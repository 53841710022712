import styles from './Form.module.css';
import {Zoom, Bounce} from "react-awesome-reveal";
import peopleImg from "./peoples.jpg"
import React from "react";
function Form(props) {
  return (
      <div>
          <br/>
          <br/>
          <div style={{background: '#276A9F'}}>
              <center>
                  <div className={styles.header}>Ablauf</div>
              </center>
          </div>
          <div className="wrapper">


              <div className="grid">
                  <div className="displayLarge lg:col-6 col-12">
                      <img className={styles.img} src={peopleImg}/>
                  </div>
                  <div className="lg:col-6 col-12">
                      <div className="displayLarge" style={{height: 80}}>
                      </div>
                      <Bounce triggerOnce>
                          <div className={styles.item}>
                              <table>
                                  <tbody>
                                  <tr>
                                      <td>
                                          <div className={styles.itemNumber}>1</div>
                                      </td>
                                      <td>
                                          <div className={styles.itemHeader}>Terminvereinbarung</div>
                                          <div className={styles.itemText}>Finden Sie im Kalender einen passenden Termin</div>
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </Bounce>

                      <Bounce triggerOnce delay={1000}>
                          <div className={styles.item}>
                              <table>
                                  <tbody>
                                  <tr>
                                      <td>
                                          <div className={styles.itemNumber}>2</div>
                                      </td>
                                      <td>
                                          <div className={styles.itemHeader}>Bearbeitung</div>
                                          <div className={styles.itemText}>Wir besprechen im Termin Ihre Wünsche und Anforderungen</div>
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </Bounce>

                      <Bounce triggerOnce delay={2000}>
                          <div className={styles.item}>
                              <table>
                                  <tbody>
                                  <tr>
                                      <td>
                                          <div className={styles.itemNumber}>3</div>
                                      </td>
                                      <td>
                                          <div className={styles.itemHeader}>Angeboterstellung</div>
                                          <div className={styles.itemText}>Sie erhalten ein unverbindliches Angebot</div>
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </Bounce>
                  </div>
              </div>
          </div>
      </div>

  );
}

export default Form;
