import imgLogo from './imgLogo.png'
import imgOnlyLogo from './imgOnlyLogo.png'
import styles from './Form.module.css';
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import React, {useRef} from "react";
function Form(props) {
    const menu = useRef(null);

    let items = [
        {label: 'Home', command:() => props.onItemSelect &&  props.onItemSelect('HOME')},
        {label: 'Leistung', command:() => props.onItemSelect && props.onItemSelect('LEISTUNG')},
        {label: 'Kontakt', command:() => props.onItemSelect &&  props.onItemSelect('KONTAKT')},
    ];
  return (
    <div>
        <div className={styles.root} style={{height: window.innerWidth >= 992 ? 80: 70}}>

            <div style={{position: 'absolute'}}>
                <div style={{color: 'rgba(255,0,0,0)'}}>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Büroreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Verkaufsflächenreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Treppenhausreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Eventreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Glasreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Bauendreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Praxenreinigung</h1>
                    <h1 style={{fontSize: "6px", margin: "0px"}}>Großküchenreinigung</h1>
                </div>
                <div style={{fontSize: "6px", color: 'rgba(255,0,0,0)'}}>Büroreinigung Verkaufsflächenreinigung Treppenhausreinigung Eventreinigung Glasreinigung Bauendreinigung Praxenreinigung Großküchenreinigung</div>
            </div>
            <div className={"wrapperSlim"}>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        {
                            window.innerWidth >= 992 ? (
                                <td>
                                    <img src={imgLogo} style={{width: 200, cursor: 'pointer'}} onClick={() => props.onItemSelect && props.onItemSelect("HOME")}/>
                                </td>
                            ) : (
                                <td>
                                    <img src={imgOnlyLogo} style={{width: 30, cursor: 'pointer'}} onClick={() => props.onItemSelect && props.onItemSelect("HOME")}/>
                                </td>
                            )
                        }
                        <td style={{width: '100%'}} className="displayLarge">
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    {items.map(item => (
                                        <td className={styles.itemCell} onClick={() => item.command()}>
                                            <div className={styles.item}>
                                                {item.label}
                                            </div>
                                        </td>
                                    ) )}

                                </tr>
                                </tbody>
                            </table>
                        </td>

                        <td style={{width: '100%', textAlign: 'center', margin: 0}}>
                            <button className="terminButton" onClick={() => props.onItemSelect &&  props.onItemSelect('TERMIN_VEREINBAREN')}>
                                <div style={{marginBottom: -2, fontSize: window.innerWidth >= 992 ? 22 : 16}}>Termin vereinbaren</div>
                                <div style={{fontSize: window.innerWidth >= 992 ? 16 : 10}}>kostenloses Angebot</div>
                            </button>
                        </td>

                        <td className="displaySmall" style={{textAlign: 'right'}}>

                            <div>
                                <Menu model={items} popup ref={menu} id="popup_menu" style={{marginTop: 27}}/>
                                <Button
                                    icon="pi pi-bars"
                                    onClick={(event) => menu.current.toggle(event)}
                                    aria-controls="popup_menu"
                                    aria-haspopup />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style={{height: window.innerWidth >= 992 ? 80: 70}}>
        </div>
    </div>
  );
}

export default Form;
