import imgBackground from "./imgBackground.jpg";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Slide, Zoom} from "react-awesome-reveal";
import React, {useEffect, useState} from "react";
import {AppApi} from "../AppApi";
function Form(props) {

    const [datenschutzbestimmungen, setDatenschutzbestimmungen] = useState(localStorage.getItem("datenschutzbestimmungen"));

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [message, setMessage] = useState(null);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isTelephoneValid, setIsTelephoneValid] = useState(true);



    useEffect(() => {
        const handleStorage = () => {
            setDatenschutzbestimmungen(localStorage.getItem("datenschutzbestimmungen"))
        }
        window.addEventListener('storage', () => handleStorage())
    }, [])


    const actions = {
        post: () => {

            let isNameValid = true
            let isEmailValid = true
            let isTelephoneValid = true

            if(name == null) {
                isNameValid = false
            }

            if(email == null || !email.includes("@") || !email.includes(".")) {
                isEmailValid = false
            }

            if(telephone == null) {
                isTelephoneValid = false
            }


            if(isNameValid && isEmailValid && isTelephoneValid) {
                let postForm = {
                    token: '249b8775-15c8-4520-827d-668fa7c094ca'
                    , name: name
                    , email: email
                    , telephone: telephone
                    , fields: [
                        {
                            type: "TEXT",
                            value: message,
                            label: "Nachricht"
                        }
                    ]
                }
                AppApi.pub.form.post(postForm)
                    .then(response => {
                        window.location.href = "/danke/kontakt"
                    })
                    .catch(console.log)
            } else {
                setIsNameValid(isNameValid)
                setIsEmailValid(isEmailValid)
                setIsTelephoneValid(isTelephoneValid)
            }

        },
    }
  return (
      <div ref={props.childRef}>
          <center>
              <div style={{
                  fontSize: '60px'
                  , fontWeight: 'bold'
                  , background: '#FFFFFF'
                  , color: '#276A9F'
                  , padding: '20px'
              }}>Kontaktieren Sie uns!</div>
          </center>
          <div style={{backgroundImage: 'url("' + imgBackground + '")'}}>


              <div
                  className="hidden xl:block">
                  <div className="grid grid-nogutter" style={{paddingTop: 100, paddingBottom: 100}}>
                      <div className="col-7">
                          <Zoom triggerOnce direction="left">
                              <div style={{
                                  color: '#FFFFFF'
                                  , marginTop: '150px'
                                  , fontSize: '70px'
                                  , padding: '20px'
                                  , background: 'rgba(255,255,255,0.4)'
                                  , borderTopRightRadius: '300px'
                                  , borderBottomRightRadius: '300px'
                              }}>
                                  <div style={{
                                      maxWidth: '42rem'
                                      , marginLeft: 'auto'
                                  }}>Rufen Sie uns an, wir beraten Sie gern!</div>

                              </div>
                          </Zoom>
                      </div>

                      <div className="col-5">

                          <Zoom triggerOnce direction="right">
                              <div style={{
                                  maxWidth: '28rem'
                                  , marginLeft: '20px'
                                  , marginRight: 'auto'
                                  , borderRadius: 15
                                  , background: 'rgba(255,255,255,0.4)'
                                  , padding: 15
                              }}>
                                  <div style={{
                                      background: '#276A9F'
                                      , height: 10
                                      , borderRadius: 10
                                      , width: '30%'
                                      , margin: '10px 0px 10px 0px'
                                  }}/>
                                  <div style={{fontSize: 32}}>Fragen Sie jetzt unverbindlich an</div>
                                  <InputText
                                      className={isNameValid ? null : 'p-invalid'}
                                      style={{width: '100%', marginBottom: 10}}
                                      placeholder="Firmenname / Name"
                                      onChange={(event) => setName(event.target.value)}/>
                                  <InputText
                                      className={isEmailValid ? null : 'p-invalid'}
                                      style={{width: '100%', marginBottom: 10}}
                                      placeholder="E-Mail"
                                      onChange={(event) => setEmail(event.target.value)}/>
                                  <InputText
                                      className={isTelephoneValid ? null : 'p-invalid'}
                                      style={{width: '100%', marginBottom: 10}}
                                      placeholder="Telefonnummer"
                                      onChange={(event) => setTelephone(event.target.value)}/>
                                  <InputTextarea
                                      rows={5}
                                      style={{width: '100%'}}
                                      placeholder="Ihre Nachricht"
                                      onChange={(event) => setMessage(event.target.value)}/>

                                  {datenschutzbestimmungen === 'false' && (
                                      <div style={{fontSize: 14}}>
                                          Damit Sie die Nachricht senden können müssen Sie<br/> die <a href="/datenschutz">Datenschutzbestimmungen</a> lesen und zustimmen!
                                      </div>
                                  )}
                                  {datenschutzbestimmungen === 'false' && (
                                      <div className="buttonSuccess" onClick={() => {
                                          localStorage.setItem("datenschutzbestimmungen", true)
                                          window.dispatchEvent(new Event('storage'))
                                      }}>Zustimmen</div>
                                  )}
                                  {datenschutzbestimmungen === 'true' && (
                                      <div className="button" onClick={() => actions.post()}>senden</div>
                                  )}

                              </div>
                          </Zoom>
                      </div>
                  </div>
              </div>
              <div
                  className="block xl:hidden">
                  <div style={{padding: 20}}>
                      <Zoom triggerOnce>

                          <div style={{
                              borderRadius: 15
                              , background: 'rgba(255,255,255,0.4)'
                              , padding: 15
                          }}>
                              <div style={{
                                  background: '#276A9F'
                                  , height: 10
                                  , borderRadius: 10
                                  , width: '30%'
                                  , margin: '10px 0px 10px 0px'
                              }}/>
                              <div style={{fontSize: 32}}>Fragen Sie jetzt unverbindlich an</div>
                              <InputText placeholder="Firmenname / Name" style={{width: '100%', marginBottom: 10}}/>
                              <InputText placeholder="E-Mail" style={{width: '100%', marginBottom: 10}}/>
                              <InputText placeholder="Telefonnummer" style={{width: '100%', marginBottom: 10}}/>
                              <InputTextarea placeholder="Ihre Nachricht" rows={5} style={{width: '100%'}}/>
                              <div className="button" onClick={() => actions.post()}>senden</div>
                          </div>
                      </Zoom>
                  </div>
              </div>


          </div>
      </div>
  );
}

export default Form;
