import styles from './Form.module.css';
import imgZertifikat from './imgZertifikat.png'
import React from "react";
function Form() {
  return (
    <div>

        <div style={{background: '#276A9F', height: 5}}></div>
        <div style={{background: '#14496B'}}>

            <div className="wrapperSlim">
                <div className={styles.header}>Sie haben Fragen?</div>
                <div className="grid">
                    <div className="lg:col-6 col-12">
                        <div className={styles.text}>Lassen Sie uns persönlich über Ihr Thema sprechen und gemeinsam die optimale Lösung für Sie finden.</div>
                        <br/>

                        <a href="tel://04021972991" style={{textDecoration: 'none'}}>
                            <div className={styles.phone}>040 21 97 29 91</div>
                        </a>
                    </div>
                    <div className="lg:col-6 col-12">
                        <div style={{textAlign: 'right'}}>
                            <img src={imgZertifikat} style={{width: '120px'}}/>
                            <div className={styles.text} style={{fontSize: 19}}>Zertifiziert nach ISO 9001:2015</div>
                        </div>
                    </div>

                </div>

                <center>
                    <span className={styles.link} onClick={() => window.location.href = "/kontakt"}>Kontakt</span>
                    <span style={{color: '#FFFFFF'}}> · </span>
                    <span className={styles.link} onClick={() => window.location.href = "/impressum"}>Impressum</span>
                    <span style={{color: '#FFFFFF'}}> · </span>
                    <span className={styles.link} onClick={() => window.location.href = "/datenschutz"}>Datenschutz</span>
                </center>
            </div>
        </div>
        <div style={{background: '#276A9F', padding: 10, color: '#FFFFFF', textAlign: 'center'}}>
            Copyright © 2023
        </div>
    </div>
  );
}

export default Form;
