import axios from "axios";


//const HOST = window.location.protocol+"//localhost:8080"
const HOST = ''


export const _GET = (url, filter) => axios.get(HOST + url + convertFilter(filter), createConfig(url)).then(processSuccess).catch(processError)
export const _POST = (url, body) => axios.post(HOST + url, body, createConfig(url)).then(processSuccess).catch(processError)
export const _DELETE = (url) => axios.delete(HOST + url, createConfig(url)).then(processSuccess).catch(processError)




const createConfig = (url, timeout) => {
    let config = {
        headers: {
            'Content-Type': 'application/json'
        },
        timeout: timeout
    }
    if (url.startsWith('/api/prv/')) {
        config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            },
            timeout: timeout
        };
    }
    return config
}

const convertFilter = (filter) => {
    let queryFilter = ''
    if(filter != null) {
        let filterCopy = JSON.parse(JSON.stringify(filter))
        Object.keys(filterCopy).forEach((key) => (filterCopy[key] == null) && delete filterCopy[key]);
        queryFilter = '?' + new URLSearchParams(filterCopy).toString()
    }
    return queryFilter
}

const processSuccess = (response) => {
    if(response.headers['profile-jwt-token'] != null) {
        localStorage.setItem("jwtToken", response.headers['profile-jwt-token']);
        localStorage.setItem("jwtTokenMinutes", "59");
        localStorage.setItem("jwtTokenSeconds", "59");
        localStorage.setItem("role", response.headers['profile-role']);
        localStorage.setItem("CONTACT", response.headers['profile-modules'].includes("CONTACT"));
        localStorage.setItem("PROJECT", response.headers['profile-modules'].includes("PROJECT"));
        localStorage.setItem("FAKTURA", response.headers['profile-modules'].includes("FAKTURA"));
        localStorage.setItem("EMAIL", response.headers['profile-modules'].includes("EMAIL"));
        localStorage.setItem("username", response.headers['profile-username']);
        localStorage.setItem("profileId", response.headers['profile-id']);
        localStorage.setItem("profileFirstname", response.headers['profile-firstname']);
        localStorage.setItem("profileLastname", response.headers['profile-lastname']);
        localStorage.setItem("profileColor", response.headers['profile-color']);
    }

    //EvenEmitter.dispatch("NOTIFICATION_HTTP_SUCCESS_RESPONSE", response.data)
    return new Promise(function(resolve, reject) {
        resolve(Promise.resolve(response));
    })
}

const processError = (error) => {
    if(error.response.status == 401) {
        localStorage.clear()
        window.location.href = "/";
    } else {
        //EvenEmitter.dispatch("NOTIFICATION_HTTP_ERROR_RESPONSE", error.response.data)
    }
    return Promise.reject(error)
}


