import styles from './Form.module.css';
import {Zoom} from "react-awesome-reveal";
function Form(props) {
  return (
      <div className="wrapper" style={{paddingTop: 100, paddingBottom: 100}}>
          <Zoom direction="right" triggerOnce>
              <div className={styles.contactForm}>
                  <table>
                      <tbody>
                      <tr>
                          <td>
                              <div style={{fontSize: 80, paddingLeft: 20, paddingRight: 20, color: 'green'}}>
                                  <i style={{fontSize: 48}} className="pi pi-check"></i>
                              </div>
                          </td>
                          <td>
                              <div className="venmediaHeader">Ihre Kontakanfrage ist bei uns eingegangen!</div>
                              <div>Sie erhalten in kürze eine Bestätigungsmail.</div><br/>
                              Nach Prüfung Ihrer Angaben, melden wir uns unverzüglich mit einem persönlichen Angebot an Sie.
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </Zoom>
      </div>
  );
}

export default Form;
