import styles from './Form.module.css';
import {Zoom} from "react-awesome-reveal";
function Form(props) {
  return (
      <div className="wrapper" style={{paddingTop: 100, paddingBottom: 100}}>
          <h1>AGB</h1>
      </div>
  );
}

export default Form;
