import {_DELETE, _DOWNLOAD, _POST, _GET} from "./Api"

export const AppApi = {

    pub: {
        form: {
            url: '/api/pub/contacts/leads',
            post: (content) => _POST(AppApi.pub.form.url, JSON.stringify(content)),
            postNEW: (token, content) => {
                if(
                    content.email.includes("@gmail")
                    || content.email.includes("@googlemail.")
                    || content.email.includes("@gmx.")
                    || content.email.includes("@icloud.")
                    || content.email.includes("@hotmail.")
                    || content.email.includes("@web.")
                ){
                    alert("Lieber Nutzer, wir konnte ihre Anfrage nich verarbeiten, weil es sich um eine Private Anfrage handelt. Sie können uns gerne direkt Anrufen!")
                } else {
                    return _POST(AppApi.pub.form.url, JSON.stringify({token: token, content: JSON.stringify(content)}))
                }
            },
        },
        task: {
            dueDate: {
                url: '/api/tasks/dueDate',
                get: (isoDate) => _GET(AppApi.pub.task.dueDate.url, isoDate)
            }
        }
    },
}