import styles from './Form.module.css';
import React, {useEffect, useState} from 'react';
import imgRef1 from "./ref1.png"
import imgRef2 from "./ref2.png"
import imgRef3 from "./ref3.jpg"
import imgRef4 from "./ref4.PNG"
import imgRef5 from "./ref5.jpg"
import imgRef6 from "./ref6.jpg"
import imgRef7 from "./ref7.png"
import imgRef8 from "./ref8.png"
import imgRef9 from "./ref9.png"
import imgRef10 from "./ref10.jpg"
import imgRef11 from "./ref11.png"
import imgRef12 from "./ref12.jpeg"
import imgRef13 from "./ref13.jpg"
import imgRef14 from "./ref14.jpg"
import imgRef15 from "./ref15.png"
import imgRef16 from "./ref16.png"
import imgRef17 from "./ref17.png"
import imgRef18 from "./ref18.png"
import imgRef19 from "./ref19.png"
import imgRef20 from "./ref20.jpg"
import {Carousel} from "primereact/carousel";
import * as PropTypes from "prop-types";

function Button(props) {
    return null;
}

Button.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string,
    style: PropTypes.shape({background: PropTypes.string, float: PropTypes.string})
};

function Form(props) {


    const [page, setPage] = useState(0);

    const [index, setIndex] = useState(null);
    const [backwardIndex, setBackwardIndex] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if(index != null){
                setIndex(index+1);
                if(index == 10 ){
                    setIndex(null)
                }
            }
        }, 80)

        return () => {
            clearInterval(interval);
        }
    }, [index])

    useEffect(() => {
        const backwardInterval = setInterval(() => {
            if(backwardIndex != null){
                setBackwardIndex(backwardIndex-1);
                if(backwardIndex == 1 ){
                    setBackwardIndex(null)
                }
            }
        }, 80)

        return () => {
            clearInterval(backwardInterval);
        }
    }, [backwardIndex])

    const onMouseEnter = (index) => {
        setIndex(index)
        setBackwardIndex(index)
    }



    const charts =[
        1,
        2]


    const chartCarouselTemplate =(chart) => {
        if(chart == 1) {
            return (
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 1 || backwardIndex == 1 ? styles.imgFly : styles.img} src={imgRef1} onMouseEnter={() => onMouseEnter(1)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 2 || backwardIndex == 2 ? styles.imgFly : styles.img} src={imgRef2} onMouseEnter={() => onMouseEnter(2)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 3 || backwardIndex == 3 ? styles.imgFly : styles.img} src={imgRef3} onMouseEnter={() => onMouseEnter(3)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 4 || backwardIndex == 4 ? styles.imgFly : styles.img} src={imgRef4} onMouseEnter={() => onMouseEnter(4)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 5 || backwardIndex == 5 ? styles.imgFly : styles.img} src={imgRef5} onMouseEnter={() => onMouseEnter(5)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 1 || backwardIndex == 1 ? styles.imgFly : styles.img} src={imgRef11} onMouseEnter={() => onMouseEnter(1)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 2 || backwardIndex == 2 ? styles.imgFly : styles.img} src={imgRef12} onMouseEnter={() => onMouseEnter(2)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 3 || backwardIndex == 3 ? styles.imgFly : styles.img} src={imgRef13} onMouseEnter={() => onMouseEnter(3)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 4 || backwardIndex == 4 ? styles.imgFly : styles.img} src={imgRef14} onMouseEnter={() => onMouseEnter(4)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 5 || backwardIndex == 5 ? styles.imgFly : styles.img} src={imgRef15} onMouseEnter={() => onMouseEnter(5)}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else if(chart == 2) {
            return (
                <div>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 6 || backwardIndex == 6 ? styles.imgFly : styles.img} src={imgRef6} onMouseEnter={() => onMouseEnter(6)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 7 || backwardIndex == 7 ? styles.imgFly : styles.img} src={imgRef7} onMouseEnter={() => onMouseEnter(7)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 8 || backwardIndex == 8 ? styles.imgFly : styles.img} src={imgRef8} onMouseEnter={() => onMouseEnter(8)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 9 || backwardIndex == 9 ? styles.imgFly : styles.img} src={imgRef9} onMouseEnter={() => onMouseEnter(9)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 10 || backwardIndex == 10 ? styles.imgFly : styles.img} src={imgRef10} onMouseEnter={() => onMouseEnter(10) && setBackwardIndex(10)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 6 || backwardIndex == 6 ? styles.imgFly : styles.img} src={imgRef16} onMouseEnter={() => onMouseEnter(6)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 7 || backwardIndex == 7 ? styles.imgFly : styles.img} src={imgRef17} onMouseEnter={() => onMouseEnter(7)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 8 || backwardIndex == 8 ? styles.imgFly : styles.img} src={imgRef18} onMouseEnter={() => onMouseEnter(8)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 9 || backwardIndex == 9 ? styles.imgFly : styles.img} src={imgRef19} onMouseEnter={() => onMouseEnter(9)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 10 || backwardIndex == 10 ? styles.imgFly : styles.img} src={imgRef20} onMouseEnter={() => onMouseEnter(10)}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    return (
        <div>
            <div className="displayLarge" style={{background: '#FFFFFF'}}>
                <div className="wrapper">
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 1 || backwardIndex == 1 ? styles.imgFly : styles.img} src={imgRef1} onMouseEnter={() => onMouseEnter(1)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 2 || backwardIndex == 2 ? styles.imgFly : styles.img} src={imgRef2} onMouseEnter={() => onMouseEnter(2)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 3 || backwardIndex == 3 ? styles.imgFly : styles.img} src={imgRef3} onMouseEnter={() => onMouseEnter(3)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 4 || backwardIndex == 4 ? styles.imgFly : styles.img} src={imgRef4} onMouseEnter={() => onMouseEnter(4)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 5 || backwardIndex == 5 ? styles.imgFly : styles.img} src={imgRef5} onMouseEnter={() => onMouseEnter(5)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 6 || backwardIndex == 6 ? styles.imgFly : styles.img} src={imgRef6} onMouseEnter={() => onMouseEnter(6)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 7 || backwardIndex == 7 ? styles.imgFly : styles.img} src={imgRef7} onMouseEnter={() => onMouseEnter(7)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 8 || backwardIndex == 8 ? styles.imgFly : styles.img} src={imgRef8} onMouseEnter={() => onMouseEnter(8)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 9 || backwardIndex == 9 ? styles.imgFly : styles.img} src={imgRef9} onMouseEnter={() => onMouseEnter(9)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 10 || backwardIndex == 10 ? styles.imgFly : styles.img} src={imgRef10} onMouseEnter={() => onMouseEnter(10) && setBackwardIndex(10)}/></td>
                        </tr>
                        <tr>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 1 || backwardIndex == 1 ? styles.imgFly : styles.img} src={imgRef11} onMouseEnter={() => onMouseEnter(1)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 2 || backwardIndex == 2 ? styles.imgFly : styles.img} src={imgRef12} onMouseEnter={() => onMouseEnter(2)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 3 || backwardIndex == 3 ? styles.imgFly : styles.img} src={imgRef13} onMouseEnter={() => onMouseEnter(3)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 4 || backwardIndex == 4 ? styles.imgFly : styles.img} src={imgRef14} onMouseEnter={() => onMouseEnter(4)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 5 || backwardIndex == 5 ? styles.imgFly : styles.img} src={imgRef15} onMouseEnter={() => onMouseEnter(5)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 6 || backwardIndex == 6 ? styles.imgFly : styles.img} src={imgRef16} onMouseEnter={() => onMouseEnter(6)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 7 || backwardIndex == 7 ? styles.imgFly : styles.img} src={imgRef17} onMouseEnter={() => onMouseEnter(7)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 8 || backwardIndex == 8 ? styles.imgFly : styles.img} src={imgRef18} onMouseEnter={() => onMouseEnter(8)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 9 || backwardIndex == 9 ? styles.imgFly : styles.img} src={imgRef19} onMouseEnter={() => onMouseEnter(9)}/></td>
                            <td style={{width: '10%', textAlign: 'center'}}><img className={index == 10 || backwardIndex == 10 ? styles.imgFly : styles.img} src={imgRef20} onMouseEnter={() => onMouseEnter(10)}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div  className="displaySmall" >
                <Carousel
                    style={{padding: '40px 0px 0px 0px'}}
                    page={page}
                    onPageChange={(event) => setPage(event.page)}
                    value={charts}
                    numVisible={1}
                    numScroll={1}

                    itemTemplate={(item) => chartCarouselTemplate(item)}/>
            </div>
        </div>
    );
}

export default Form;
