import styles from './Form.module.css';
import img from './img.png'
import imgBueroreinigung from './imgBueroreinigung.png'
import imgVerkaufsflaechenreinigung from './imgVerkaufsflaechenreinigung.png'
import imgTreppenhausreinigung from './imgTreppenhausreinigung.png'
import imgAfterEventReinigung from './imgAfterEventReinigung.jpg'
import imgGlasreinigung from './imgGlasreinigung.png'
import imgBauendreinigung from './imgBauendreinigung.png'
import imgPraxenreinigung from './imgPraxenreinigung.png'
import imgGrosskuechenreinigung from './imgGrosskuechenreinigung.jpg'
import {Zoom, Flip, Bounce, Fade, Hinge, Rotate, Roll, Reveal, Slide, JackInTheBox, AttentionSeeker, } from "react-awesome-reveal";
import React, {useState} from "react";
import {ScrollPanel} from "primereact/scrollpanel";
import imgBackground from "../area01/imgBackground.png";



function Form(props) {

        const [selectedItemIndex, setSelectedItemIndex] = useState(null);

        const [items, setItems] = useState([
                {
                        label: 'Büro-',
                        service: 'Reinigung',
                        img: imgBueroreinigung,
                        text: 'Wohlfühlen können Sie sich bereits, wenn wir noch bei Ihnen vor Ort sind. Was sich aus einem Wohlfühlfaktor herausholen lässt, erleben Sie, wenn wir mit Ihrer Büroreinigung fertig sind. Das Clean Port Team hinterlässt ein Ambiente, in dem einfach jeder gerne arbeitet.'
                },
                {
                        label: 'Verkaufsflächen-',
                        service: 'Reinigung',
                        img: imgVerkaufsflaechenreinigung,
                        text: 'Die Atmosphäre in Ihren Verkaufsräumen spiegelt die Werte Ihres Unternehmens wider. Nach einer professionellen Verkaufsflächenreinigung wirkt der gepflegte Rahmen auf Ihre Kunden garantiert wieder wie ein Anziehungspunkt. Buchen Sie uns gerne regelmäßig und zu den Uhrzeiten, mit denen Sie gut leben können.'
                },
                {
                        label: 'Treppenhaus-',
                        service: 'Reinigung',
                        img: imgTreppenhausreinigung,
                        text: 'Der erste Eindruck hat einen Besucher schon oft in einen Stammkunden verwandelt. Eine erste Wahrnehmung Ihres Unternehmens wird Ihren Gästen beim Betreten eines Gebäudes direkt im Treppenhaus in Erinnerung bleiben. Das Clean Port Team kümmert sich hier gerne um ein gepflegtes und einladendes Ambiente.'
                },
                {
                        label: 'Event-',
                        service: 'Reinigung',
                        img: imgAfterEventReinigung,
                        text: 'Wo gefeiert wird, bleiben privat wie gewerblich Spuren zugibt. Wir machen es uns zur Aufgabe, den Ursprungszustand wieder herzustellen. So können Sie sich sofort nach der Veranstaltung wieder um die wirklich wichtigen Dinge kümmern. Wenn das Clean Port Team fertig ist, kann die nächste Party gerne kommen.'
                },
                {
                        label: 'Glas-',
                        service: 'Reinigung',
                        img: imgGlasreinigung,
                        text: 'Glas entscheidet über die Gesamtarchitektur eines Gebäudes. Wenn hier alles blitzt und blinkt, wird man neugierig auf das Innere. Wer saubere Fenster hat, scheint alles im Griff zu haben. Hier kann man vertrauen schenken und dabei ein helles, streifenfreies Ambiente genießen. Das Clean Port Team ist gerne mit einer professionellen Glasreinigung zur Stelle.'
                },
                {
                        label: 'Bauend-',
                        service: 'Reinigung',
                        img: imgBauendreinigung,
                        text: 'Wo gebaut wird, fällt Schmutz und Staub an. Die nächsten Gewerke können nur dann qualitätsbewusst ins Detail gehen, wenn die Räume sauber sind. Doch die Zeit drängt auch deswegen, weil der Schmutz sich im gesamten Gebäude verbreitet. Mit der Clean Port Bauendreinigung unterstützen wir Sie mit professionellem Service.'
                },
                {
                        label: 'Praxen-',
                        service: 'Reinigung',
                        img: imgPraxenreinigung,
                        text: 'In einer Praxis dreht sich alles um die Gesundheit der Besucher. Daher ist absolute Hygiene oberstes Gebot. Vom Büro über die Anmeldung bis hin zu den einzelnen Behandlungsräumen; das Clean Port Team schafft eine Sauberkeit, die Ihre Erwartungen an eine Praxisreinigung übertrifft.'
                },
                {
                        label: 'Großküchen-',
                        service: 'Reinigung',
                        img: imgGrosskuechenreinigung,
                        text: 'Seit 1995 müssen Küchen und Großküchen von Kantinen, Restaurants, Heimen, Krankenhäusern, Hotels, etc. nach dem europäischen HACCP-System (hazard analysis and critical control point) gereinigt werden. Egal ob vorsorglich oder auf Grund einer amtlichen Anordnung – gerne sind wir Ihnen bei der Sicherstellung einer hygienisch einwandfreien Küche und eines sauberen Arbeitsplatzes behilflich.'
                }
        ]);

        const ITEMOLD = (props) => (
            <div className={styles.item + " md:col-3 col-6 flex align-items-center justify-content-center"} style={{height: '350px'}}>
                    {selectedItemIndex != props.index && (
                        <div
                            style={{padding: 10}}
                            onMouseEnter={() => setSelectedItemIndex(props.index)}
                            onClick={() => setSelectedItemIndex(props.index)}>
                                <div>
                                        <div style={{textAlign: 'center'}}>
                                                <img className={styles.itemImage} src={props.img}/>
                                                <div className={styles.itemLabel}>
                                                        {props.label}
                                                        <br/>
                                                        {props.service}
                                                </div>
                                        </div>
                                </div>
                        </div>
                    )}

                    {selectedItemIndex == props.index && (
                        <ScrollPanel style={{ width: '100%', height: '330px' }}>

                                <div className={styles.scrollBar} onClick={() => setSelectedItemIndex(props.index)}>
                                        <Bounce>
                                                <div className={styles.itemLabel} style={{paddingBottom: 10, fontWeight: 'bold'}}>
                                                        {props.label}
                                                        <br/>
                                                        {props.service}
                                                </div>
                                                <div style={{fontSize: 14, textAlign: 'left'}}>{props.text}</div>
                                        </Bounce>
                                </div>
                        </ScrollPanel>

                    )}
            </div>
        )

        const ITEM = (props) => (
            <div className={styles.item + " md:col-3 col-6"} style={{height: '350px'}}>
                    {selectedItemIndex != props.index && (
                        <div
                            style={{height: '350px', backgroundImage: 'url("' + props.img + '")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
                            onMouseEnter={() => setSelectedItemIndex(props.index)}
                            onClick={() => setSelectedItemIndex(props.index)}>
                                <div>
                                        <div style={{textAlign: 'center'}}>
                                                <div style={{height: 250}}></div>
                                                <div className={styles.itemLabel} style={{background: 'rgba(241,245,248,0.75)', margin: 15, fontWeight: "bold"}}>
                                                        {props.label}
                                                        <br/>
                                                        {props.service}
                                                </div>
                                        </div>
                                </div>
                        </div>
                    )}

                    {selectedItemIndex == props.index && (
                        <ScrollPanel style={{ width: '100%', height: '330px' }}>
                                <div className={styles.scrollBar} onClick={() => setSelectedItemIndex(props.index)}>
                                        <Bounce>
                                                <div className={styles.itemLabel} style={{paddingBottom: 10, fontWeight: 'bold'}}>
                                                        {props.label}
                                                        <br/>
                                                        {props.service}
                                                </div>
                                                <div style={{fontSize: 14, textAlign: 'left', paddingBottom: 20}}>{props.text}</div>
                                        </Bounce>
                                </div>
                        </ScrollPanel>

                    )}
            </div>
        )

  return (
      <div ref={props.childRef} className={styles.root}>

              <div style={{background: '#276A9F'}}>
                      <center>
                              <div className={styles.header}>Unser Service</div>
                      </center>
              </div>
              <div className="wrapperSlim">
                      <center>
                              <div style={{
                                      background: '#276A9F'
                                      , height: 10
                                      , borderRadius: 10
                                      , width: '15%'
                                      , margin: '30px 0px 30px 0px'
                              }}/>
                              <div className={styles.text}>Wir bringen unseren Service auf das für Sie perfekte Maß. Bei jedem Auftrag stehen die Kundenwünsche im Mittelpunkt. Mit der Berücksichtigung einzigartiger Ansprüche und Bedürfnisse konnten unsere Kompetenzen bis heute mit jedem Projekt wachsen.</div>

                      </center>
                      <br/>

                      <div className="grid grid-nogutter" style={{border: 'solid 1px #F1F5F8'}}>
                              {items.map( (item, index) => (
                                  <ITEM
                                      index={index}
                                      label={item.label}
                                      service={item.service}
                                      img={item.img}
                                      text={item.text}
                                  />
                              )) }
                      </div>
              </div>
      </div>
  );
}

export default Form;
