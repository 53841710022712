import styles from './Form.module.css';
import {Slide, Zoom} from "react-awesome-reveal";
function Form(props) {
  return (
      <div>
          <div style={{background: '#276A9F', padding: 20}}>
              <center>
                  <div className={styles.headerText}>3 Gründe warum wir der Dienstleister sind, den Sie suchen</div>
              </center>
          </div>
          <div className={"wrapper"}  style={{padding: 40}}>
              <div className="grid grid-nogutter">
                  <div className={styles.item1 + " lg:col-4 col-12"}>
                      <Zoom triggerOnce>
                      <div className={styles.itemHeader}>
                          <div className={styles.itemNumber}>1</div>
                          Unkompliziert
                      </div>
                      <div className={styles.itemText}>Sie Fragen an, wir kommen innerhalb von 48 Std. vorbei und bieten Ihnen kompakte Reinigungsdienstleistungen zu fairen Konditionen an. <br/><br/>Ein persöhnlicher Ansprechpartner steht Ihnen immer zur Verfügung.</div>
                      </Zoom>
                  </div>
                  <div className={styles.item2 + " lg:col-4 col-12"}>
                      <Zoom triggerOnce delay={1000}>
                      <div className={styles.itemHeader}>
                          <div className={styles.itemNumber}>2</div>
                          Qualitativ
                      </div>
                      <div className={styles.itemText}>Ihre Zufriedenheit steht für uns an erster Stelle. <br/><br/>Interne sowie externe Qualitätsprüfungen sorgen für permanent gehobenen Service.</div>
                      </Zoom>
                  </div>
                  <div className={styles.item1 + " lg:col-4 col-12"}>
                      <Zoom triggerOnce delay={2000}>
                      <div className={styles.itemHeader}>
                          <div className={styles.itemNumber}>3</div>
                          Professionell
                      </div>
                      <div className={styles.itemText}>Unser Team Arbeitet Professionell und sorgfältig. Reinigungskräfte, Supervisor oder Beschwerde-Management. <br/><br/>Schnell werden Sie feststellen, dass bei uns alles etwas gründlicher ist.</div>
                      </Zoom>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Form;
