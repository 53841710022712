import './App.css';
import {Route, BrowserRouter, Routes} from "react-router-dom";

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';

import React, {Component, useEffect, useState} from 'react';
import HeaderForm from "./header/Form";
import Area01Form from "./area01/Form";
import Area02Form from "./area02/Form";
import Area03Form from "./area03/Form";
import Area04Form from "./area04/Form";
import Area05Form from "./area05/Form";
import Area06Form from "./area06/Form";
import Area07Form from "./area07/Form";
import Area08Form from "./area08/Form";
import KontaktForm from "./kontakt/Form";
import FooterForm from "./footer/Form";
import RequestreceivedForm from "./requestreceived/Form"
import ImpressumForm from "./impressum/Form"
import DatenschutzForm from "./datenschutz/Form"
import AgbForm from "./agb/Form"
import imgLogo from "./header/imgLogo.png";

function App() {

    const isHome = !window.location.href.includes("datenschutz") && !window.location.href.includes("impressum") && !window.location.href.includes("kontakt")

    const [cookiesAccept, setCookiesAccept] = useState(false);

    const [forceUpdate, setForceUpdate] = useState(true);

    const videoRef = React.createRef()



    const homeRef = React.createRef()
    const leistungenRef = React.createRef()
    const terminVereinbarenRef = React.createRef()
    const kontaktRef = React.createRef()

    addLocale('de', {
        firstDayOfWeek: 1,
        dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthNamesShort: ['Jan', 'Geb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Drp', 'Okt', 'Nov', 'Dez'],
        today: 'Heute',
        clear: 'Leer'
    });

    locale('de')

  return (
    <div className="App">

        {isHome && !cookiesAccept && (
            <div style={{position: "fixed", zIndex: 8888, height: '100vh', width: '100%', background: 'rgba(0,0,0,0.30)'}}>

                <center>
                    <div style={{background: '#FFFFFF', width: 300, marginTop: '30vh', padding: 20, border: '1px solid #000000', boxShadow: '5px 5px 5px #000000'}}>

                        <img src={imgLogo} style={{width: 200}} />

                        <b>Datenschutzeinstellungen</b>
                        <div style={{fontSize: 12, paddingTop: 10, paddingBottom: 10}}>
                            Nähere Informationen finden Sie in unseren <a href="/datenschutz">Datenschutzbestimmungen</a>.
                        </div>
                        <div className="button"
                             style={{margin: '5px'}}
                             onClick={() => {
                                 setCookiesAccept(true)
                                 localStorage.setItem("datenschutzbestimmungen", false)
                                 window.dispatchEvent(new Event('storage'))
                                 videoRef?.current?.play()
                             }}>ablehnen
                        </div>
                        <div className="buttonSuccess"
                             style={{margin: '5px'}}
                             onClick={() => {
                                 setCookiesAccept(true)
                                 localStorage.setItem("datenschutzbestimmungen", true)
                                 window.dispatchEvent(new Event('storage'))
                                 videoRef?.current?.play()
                             }}>zustimmen
                        </div>
                        <div style={{fontSize: 12, paddingTop: 10, paddingBottom: 10}}>
                            <a href="/impressum">Impressum</a> • <a href="/datenschutz">Datenschutzbestimmungen</a>
                        </div>
                    </div>
                </center>

            </div>
        )}
        <HeaderForm
            onItemSelect={(item) => {
                if (item == 'HOME') {
                    if(homeRef.current == null) {
                        window.location.href = "/"
                    } else {
                        homeRef.current.scrollIntoView()
                    }
                } else if(item == 'LEISTUNG') {
                    if(homeRef.current == null) {
                        window.location.href = "/"
                    } else {
                        leistungenRef.current.scrollIntoView()
                    }
                } else if(item == 'TERMIN_VEREINBAREN') {
                    if(homeRef.current == null) {
                        window.location.href = "/"
                    } else {
                        terminVereinbarenRef.current.scrollIntoView()
                    }
                } else if(item == 'KONTAKT') {
                    window.location.href = "/kontakt"
                }
            }}/>
        <BrowserRouter>
            <Routes>
                <Route path = "/" element={
                    < div>
                        <Area01Form
                            videoRef={videoRef}
                            childRef={homeRef}
                            onTerminVereinbarenClick={() => {
                                if(homeRef.current == null) {
                                    window.location.href = "/"
                                } else {
                                    terminVereinbarenRef.current.scrollIntoView()
                                }
                            }}/>
                        <Area02Form/>
                        <Area08Form/>

                        <Area04Form childRef={leistungenRef}/>
                        <Area05Form/>
                        <Area06Form/>
                        <Area03Form/>

                        <Area07Form childRef={terminVereinbarenRef}/>
                        <KontaktForm/>
                    </div>
                }/>
                <Route path="/danke/terminVereinbarung" element={<RequestreceivedForm/>}/>
                <Route path="/kontakt" element={<KontaktForm/>}/>
                <Route path="/danke/kontakt" element={<RequestreceivedForm/>}/>
                <Route path="/impressum" element={<ImpressumForm/>}/>
                <Route path="/datenschutz" element={<DatenschutzForm/>}/>
                <Route path="/agb" element={<AgbForm/>}/>
            </Routes>
        </BrowserRouter>
        <FooterForm/>



    </div>
  );
}

export default App;
