import styles from './Form.module.css';
import imgBackground from './imgBackground.png'
import React, {useEffect, useState} from "react";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {AppApi} from "../AppApi";
import {Checkbox} from "primereact/checkbox";
import pfeilImg from "./pfeil.png";


function Form(props) {


    const [datenschutzbestimmungen, setDatenschutzbestimmungen] = useState(localStorage.getItem("datenschutzbestimmungen"));
    const [step, setStep] = useState(1);
    const [date, setDate] = useState(null);
    const [humanDate, setHumanDate] = useState(null);
    const [isoDate, setIsoDate] = useState(null);
    const [time, setTime] = useState(null);
    const [time09, setTime09] = useState(false);
    const [time10, setTime10] = useState(false);
    const [time11, setTime11] = useState(false);
    const [time12, setTime12] = useState(false);
    const [time13, setTime13] = useState(false);
    const [time14, setTime14] = useState(false);
    const [time15, setTime15] = useState(false);

    const [name, setName] = useState(null);
    const [street, setStreet] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);
    const [email, setEmail] = useState(null);
    const [telephone, setTelephone] = useState(null);
    const [message, setMessage] = useState(null);
    const [reinigungsdienstleister, setReinigungsdienstleister] = useState(false);
    const [reinigungsFlaeche, setReinigungsFlaeche] = useState('100 bis 300');
    const [reinigungsintervall, setReinigungsintervall] = useState(1);
    const [reinigungsBereich, setReinigungsintervallBereich] = useState('Büro/Sanitär');


    const [isNameValid, setIsNameValid] = useState(true);
    const [isStreetValid, setIsStreetValid] = useState(true);
    const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
    const [isCityValid, setIsCityValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isTelephoneValid, setIsTelephoneValid] = useState(true);


    useEffect(() => {
        if(date) {
            setTime(null)
            let iso = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()

            actions.getDueDate(iso, "09:00")
            actions.getDueDate(iso, "10:00")
            actions.getDueDate(iso, "11:00")
            actions.getDueDate(iso, "12:00")
            actions.getDueDate(iso, "13:00")
            actions.getDueDate(iso, "14:00")
            actions.getDueDate(iso, "15:00")
            setIsoDate(iso)
            let human = iso.slice(8, 10) +"."+ iso.slice(5, 7) + "." + iso.slice(0, 4)
            setHumanDate(human)
        } else {
            setTime(null)
            setIsoDate(null)
            setHumanDate(null)
        }
    }, [date])

    useEffect(() => {
        if(time) {
            setIsoDate(isoDate.replace("T00:00", "T" + time))
        }
    }, [time])


    useEffect(() => {
        const handleStorage = () => {
            setDatenschutzbestimmungen(localStorage.getItem("datenschutzbestimmungen"))
        }
        window.addEventListener('storage', () => handleStorage())
    }, [])


    const actions = {
        getDueDate: (isoDate, time) => {
            let isoDatetime = isoDate.replace("T00:00", "T" + time)
            AppApi.pub.task.dueDate.get({dueDate: isoDatetime})
                .then(response => {
                    if(time == "09:00") {
                        setTime09(response.data)
                    } else if(time == "10:00") {
                        setTime10(response.data)
                    } else if(time == "11:00") {
                        setTime11(response.data)
                    } else if(time == "12:00") {
                        setTime12(response.data)
                    } else if(time == "13:00") {
                        setTime13(response.data)
                    } else if(time == "14:00") {
                        setTime14(response.data)
                    } else if(time == "15:00") {
                        setTime15(response.data)
                    }


                    if(date.getDay() == 1) {
                        setTime09(true)
                        setTime10(true)
                    } else if(date.getDay() == 5) {
                        setTime12(true)
                        setTime13(true)
                        setTime14(true)
                    }

                })
                .catch(console.log)


        },
        post: () => {

            let isNameValid = true
            let isStreetValid = true
            let isPostalCodeValid = true
            let isCityValid = true
            let isEmailValid = true
            let isTelephoneValid = true
            if(name == null) {
                isNameValid = false
            }

            if(street == null) {
                isStreetValid = false
            }

            if(postalCode == null) {
                isPostalCodeValid = false
            }

            if(city == null) {
                isCityValid = false
            }

            if(email == null || !email.includes("@") || !email.includes(".")) {
                isEmailValid = false
            }

            if(telephone == null) {
                isTelephoneValid = false
            }

            if(isNameValid && isStreetValid && isPostalCodeValid && isCityValid && isEmailValid && isTelephoneValid) {
                let postForm = {
                    token: '916e735d-30e7-4ffb-9c96-453625fd956e'
                    , name: name
                    , email: email
                    , telephone: telephone
                    , street: street
                    , postalCode: postalCode
                    , city: city
                    , fields: [
                        {
                            type: "TEXT",
                            value: message,
                            label: "Nachricht"
                        }
                        ,{
                            type: "TEXT",
                            value: reinigungsdienstleister ? 'ja' : 'nein',
                            label: "Reinigungsdienstleister"
                        }
                        ,{
                            type: "TEXT",
                            value: reinigungsFlaeche,
                            label: "Reinigungsfläche"
                        }
                        ,{
                            type: "TEXT",
                            value: reinigungsintervall,
                            label: "Reinigungsintervalle"
                        }
                        ,{
                            type: "TEXT",
                            value: reinigungsBereich,
                            label: "Reinigung Bereich"
                        }
                    ]
                    , tasks: [
                        {
                            description: "Termin",
                            dueDate: isoDate
                        }
                    ]
                }
                AppApi.pub.form.post(postForm)
                    .then(response => {
                        setIsNameValid(true)
                        setIsStreetValid(true)
                        setIsPostalCodeValid(true)
                        setIsCityValid(true)
                        setIsEmailValid(true)
                        setIsTelephoneValid(true)
                        window.location.href = "/danke/terminVereinbarung"
                    })
                    .catch(console.log)
            } else {
                setIsNameValid(isNameValid)
                setIsStreetValid(isStreetValid)
                setIsPostalCodeValid(isPostalCodeValid)
                setIsCityValid(isCityValid)
                setIsEmailValid(isEmailValid)
                setIsTelephoneValid(isTelephoneValid)
            }

        },
        back: () => {
            setStep(1)
            setDate(null)
        }
    }
    const items = [
        { name: 'Ja', value: true },
        { name: 'Nein', value: false }
    ];
  return (
      <div>
          <div style={{backgroundImage: 'url("' + imgBackground + '")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' , paddingTop: '80px'}}>
              <center>
                  <div className={styles.header}>Buchen Sie jetzt einen Termin</div>
                  <div className={styles.text}>und wir kommen bei Ihnen vorbei!</div>
                  <img className={styles.blinkText} src={pfeilImg} style={{width: '60%', maxWidth: 400, padding: 40}}/>
              </center>
          </div>
          <div ref={props.childRef} className="wrapper">
              <center>
                  {step == 1 && (
                      <div className={styles.timePicker}>
                          <div className="grid grid-nogutter">
                              <div className={humanDate ? "lg:col-6 col-12" : "col-12"}>
                                  <div style={{padding: '20px 40px'}}>
                                      <div className={styles.timeHeader}>Tag</div>
                                  </div>
                                  <Calendar minDate={new Date()} value={date} onChange={(e) => setDate(e.value)} inline disabledDays={[0, 6]}/>
                              </div>
                              {humanDate && (
                                  <div className="lg:col-6 col-12">
                                      <div style={{padding: '20px 40px'}}>
                                          <div className={styles.timeHeader}>Uhrzeit</div>
                                      </div>
                                      <div>
                                          <div className="grid grid-nogutter">

                                              <div className="col-6">
                                                  {time09 && (
                                                      <div className={styles.timeItemBusy}>
                                                          09:00
                                                      </div>
                                                  )}
                                                  {!time09 && (
                                                      <div className={styles.timeItem} isSelected={time == "09:00" ? 'true' : 'false'} onClick={() => setTime("09:00")}>
                                                          09:00
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="col-6">
                                                  {time10 && (
                                                      <div className={styles.timeItemBusy}>
                                                          10:00
                                                      </div>
                                                  )}
                                                  {!time10 && (
                                                      <div className={styles.timeItem} isSelected={time == "10:00" ? 'true' : 'false'} onClick={() => setTime("10:00")}>
                                                          10:00
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="col-6">
                                                  {time11 && (
                                                      <div className={styles.timeItemBusy}>
                                                          11:00
                                                      </div>
                                                  )}
                                                  {!time11 && (
                                                      <div className={styles.timeItem} isSelected={time == "11:00" ? 'true' : 'false'} onClick={() => setTime("11:00")}>
                                                          11:00
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="col-6">
                                                  {time12 && (
                                                      <div className={styles.timeItemBusy}>
                                                          12:00
                                                      </div>
                                                  )}
                                                  {!time12 && (
                                                      <div className={styles.timeItem} isSelected={time == "12:00" ? 'true' : 'false'} onClick={() => setTime("12:00")}>
                                                          12:00
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="col-6">
                                                  {time13 && (
                                                      <div className={styles.timeItemBusy}>
                                                          13:00
                                                      </div>
                                                  )}
                                                  {!time13 && (
                                                      <div className={styles.timeItem} isSelected={time == "13:00" ? 'true' : 'false'} onClick={() => setTime("13:00")}>
                                                          13:00
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="col-6">
                                                  {time14 && (
                                                      <div className={styles.timeItemBusy}>
                                                          14:00
                                                      </div>
                                                  )}
                                                  {!time14 && (
                                                      <div className={styles.timeItem} isSelected={time == "14:00" ? 'true' : 'false'} onClick={() => setTime("14:00")}>
                                                          14:00
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="col-6">
                                                  {time15 && (
                                                      <div className={styles.timeItemBusy}>
                                                          15:00
                                                      </div>
                                                  )}
                                                  {!time15 && (
                                                      <div className={styles.timeItem} isSelected={time == "15:00" ? 'true' : 'false'} onClick={() => setTime("15:00")}>
                                                          15:00
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                          <div style={{borderBottom: '1px solid #a6a6a6', margin: 20}}/>
                                          <div>
                                              {humanDate} {time}
                                          </div>
                                          {humanDate && time && (
                                              <div className="button" onClick={() => setStep(2)}>weiter</div>
                                          )}

                                      </div>
                                  </div>
                              )}
                          </div>
                      </div>
                  )}


                  {step == 2 && (
                      <div className={styles.timePicker}>

                          <div style={{borderTop: '1px solid #a6a6a6', borderBottom: '1px solid #a6a6a6', margin: '20px 20px 0px 20px', fontWeight: 'bold'}}>
                              {humanDate} {time}
                          </div>
                          <div style={{color: 'green', fontWeight: 'bold', fontSize: 16, paddingTop: 10}}>
                              Ihr Termin ist fast gebucht, bitte hinterlassen Sie uns noch folgende Informationen:
                          </div>
                          <div className="grid grid-nogutter">
                              <div className="lg:col-6 col-12">
                                  <div style={{margin: '20px 20px 0px 20px'}}>
                                      <InputText
                                          className={isNameValid ? null : 'p-invalid'}
                                          style={{width: '100%', marginBottom: 10}}
                                          placeholder="Firmenname / Name"
                                          onChange={(event) => setName(event.target.value)}/>
                                      <InputText
                                          className={isStreetValid ? null : 'p-invalid'}
                                          style={{width: '100%', marginBottom: 10}}
                                          placeholder="Strasse / Hausnummer"
                                          onChange={(event) => setStreet(event.target.value)}/>
                                      <div className="grid grid-nogutter">
                                          <div className="col-4">
                                              <InputText
                                                  className={isPostalCodeValid ? null : 'p-invalid'}
                                                  style={{width: '100%', marginBottom: 10}}
                                                  placeholder="PLZ"
                                                  onChange={(event) => setPostalCode(event.target.value)}/>
                                          </div>
                                          <div className="col-8" style={{paddingLeft: 10}}>
                                              <InputText
                                                  className={isCityValid ? null : 'p-invalid'}
                                                  style={{width: '100%', marginBottom: 10}}
                                                  placeholder="Ort"
                                                  onChange={(event) => setCity(event.target.value)}/>
                                          </div>
                                          <InputText
                                              className={isEmailValid ? null : 'p-invalid'}
                                              style={{width: '100%', marginBottom: 10}}
                                              placeholder="E-Mail"
                                              onChange={(event) => setEmail(event.target.value)}/>
                                          <InputText
                                              className={isTelephoneValid ? null : 'p-invalid'}
                                              style={{width: '100%', marginBottom: 10}}
                                              placeholder="Telefonnummer"
                                              onChange={(event) => setTelephone(event.target.value)}/>
                                          <InputTextarea
                                              rows={5}
                                              style={{width: '100%'}}
                                              placeholder="Ihre Nachricht"
                                              onChange={(event) => setMessage(event.target.value)}/>
                                      </div>
                                    <br/>
                                  </div>

                              </div>
                              <div className="lg:col-6 col-12">
                                  <div style={{margin: '20px 20px 0px 20px', fontSize: 14}}>
                                      <div style={{borderTop: '1px solid #a6a6a6', padding: '15px'}}>
                                          Haben Sie bereits einen Reinigungsdienstleister?
                                          <table>
                                              <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsdienstleister(e.checked)} checked={reinigungsdienstleister}/>
                                                          <label htmlFor="ingredient1" className="ml-2">Ja</label>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsdienstleister(!e.checked)} checked={!reinigungsdienstleister}/>
                                                          <label htmlFor="ingredient1" className="ml-2">Nein</label>
                                                      </div>
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>

                                      <div style={{borderTop: '1px solid #a6a6a6', padding: '15px'}}>
                                          Wie Gross ist Ihre Fläche?
                                          <table>
                                              <tbody>
                                              <tr>
                                                  <td style={{whiteSpace: 'nowrap'}}>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsFlaeche('100 bis 300')} checked={reinigungsFlaeche == '100 bis 300'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">100 bis 300 qm</label>
                                                      </div>
                                                  </td>
                                                  <td style={{whiteSpace: 'nowrap'}}>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsFlaeche('300 bis 500')} checked={reinigungsFlaeche == '300 bis 500'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">300 bis 500 qm</label>
                                                      </div>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td style={{whiteSpace: 'nowrap'}}>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsFlaeche('500 bis 1000')} checked={reinigungsFlaeche == '500 bis 1000'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">500 bis 1000 qm</label>
                                                      </div>
                                                  </td>
                                                  <td style={{whiteSpace: 'nowrap'}}>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsFlaeche('1000 aufwärts')} checked={reinigungsFlaeche == '1000 aufwärts'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">1000 qm aufwärts</label>
                                                      </div>
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>

                                      <div style={{borderTop: '1px solid #a6a6a6', padding: '15px'}}>
                                          Gewünschte Reinigungsintervalle?
                                          <table>
                                              <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervall(1)} checked={reinigungsintervall == 1}/>
                                                          <label htmlFor="ingredient1" className="ml-2">1x wö.</label>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervall(2)} checked={reinigungsintervall == 2}/>
                                                          <label htmlFor="ingredient1" className="ml-2">2x wö.</label>
                                                      </div>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervall(3)} checked={reinigungsintervall == 3}/>
                                                          <label htmlFor="ingredient1" className="ml-2">3x wö.</label>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervall(5)} checked={reinigungsintervall == 5}/>
                                                          <label htmlFor="ingredient1" className="ml-2">5x wö.</label>
                                                      </div>
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>

                                      <div style={{borderTop: '1px solid #a6a6a6', borderBottom: '1px solid #a6a6a6', padding: '15px'}}>

                                          Welche Bereiche sollen gereingt werden?
                                          <div>

                                          </div>
                                          <table>
                                              <tbody>
                                              <tr>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervallBereich('Büro/Sanitär')} checked={reinigungsBereich == 'Büro/Sanitär'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">Büro/Sanitär</label>
                                                      </div>
                                                  </td>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervallBereich('Büro/Sanitär/Glas')} checked={reinigungsBereich == 'Büro/Sanitär/Glas'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">Büro/Sanitär/Glas</label>
                                                      </div>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                      <div className="flex align-items-center">
                                                          <Checkbox onChange={e => setReinigungsintervallBereich('Andere Bereiche')} checked={reinigungsBereich == 'Andere Bereiche'}/>
                                                          <label htmlFor="ingredient1" className="ml-2">Andere Bereiche</label>
                                                      </div>
                                                  </td>
                                                  <td></td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </div>

                                  </div>
                                  {datenschutzbestimmungen === 'false' && (
                                      <div style={{fontSize: 14}}>
                                          Damit Sie die Buchen absenden können müssen Sie<br/> die <a href="/datenschutz">Datenschutzbestimmungen</a> lesen und zustimmen!
                                      </div>
                                  )}
                                  <div className="button" onClick={() => actions.back()}>zurück</div>

                                  {datenschutzbestimmungen === 'false' && (
                                      <div className="buttonSuccess" onClick={() => {
                                          localStorage.setItem("datenschutzbestimmungen", true)
                                          window.dispatchEvent(new Event('storage'))
                                      }}>Zustimmen</div>
                                  )}
                                  {datenschutzbestimmungen === 'true' && (
                                      <div className="buttonSuccess" onClick={() => actions.post()}>buchen</div>
                                  )}
                              </div>
                          </div>
                      </div>
                  )}



              </center>
          </div>
      </div>
  );
}

export default Form;
